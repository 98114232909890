<template>
  <v-row>    
    <v-col cols="12" sm="6">
      <v-text-field label="* Descricao"
                    dense
                    :rules="[
                        this.$validators.string.required,
                            v => this.$validators.string.greaterThanOrEquals(v, 1),
                        v => this.$validators.string.lessThanOrEquals(v, 200)
                    ]"
                    v-model.trim="value.descricao"/>
    </v-col>
    <v-col cols="12" md="3">
      <search-box
          label="* Orgão"
          popup-label="Selecione um Orgão"
          :id.sync="value.idOrgao"
          :descricaoFormatada.sync="value.descricaoOrgao"
          :hidden-clear="false"
          :value="orgao"
          :loader-fn="loaderOrgao"
          item-key="id"
          item-text="descricaoFormatada"
          :rules="[
              this.$validators.notNullOrUndefined
          ]">
        <template v-slot:empty>
          <p class="ma-2">
            Nenhum Orgão encontrado
          </p>
        </template>
      </search-box>
    </v-col>
    <v-col cols="12" md="3">
      <search-box ref="comboUnidade"
          label="* Unidade Responsável"
          popup-label="Selecione uma Unidade Responsável"
          :id.sync="value.idUnidade"
          :descricaoFormatada.sync="value.descricaoUnidade"
          :hidden-clear="false"
          :value="unidade"
          :loader-fn="loaderUnidade"
          item-key="id"
          item-text="descricaoFormatada"
          :rules="[
              this.$validators.notNullOrUndefined
          ]">
        <template v-slot:empty>
          <p class="ma-2">
            Nenhuma Unidade encontrada
          </p>
        </template>
      </search-box>
    </v-col>
    <v-col cols="6"> <!-- Itens a ser pesquisados -->
      <CardPersonalizado 
                  title="Adicionar Itens do Plano à Contratação"
                  :showConteudo="true"
                  :loading="loading"
                  >
        <div>
          <v-row>
            <v-col cols="12" md="12">
              <v-text-field label="* Busque o item pelo código ou descrição"
                  dense
                  v-model.trim="descItemPesquisa"/>
            </v-col>
            <v-divider/> 
          </v-row>
          <PacDataTable ref="itensDoPacLicitacaoNovos" 
            :force-hidden-header="true"
            :search-values="{
              qtdRegPorPagina: 5,
              idUnidade: value.idUnidade,
              idsPacNotIn: this.value.idsPac,
              descItemPesquisa: this.descItemPesquisa,
            }">
            <template v-slot:body="{items}">
              <v-list dense class="pa-0">
                <v-list-item v-for="pac of items" :key="pac.id"
                    @click="addItemPac(pac)" class="itens-pac add">
                  <v-list-item-avatar>
                    #{{ pac.id }}
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title v-text="pac.cdItem"/>
                    <v-list-item-subtitle v-text="pac.descricaoItem"/>
                    <v-list-item-subtitle v-text="pac.descricaoOrgao"/>
                    <v-list-item-subtitle v-text="pac.descricaoUnidade"/>
                    <v-list-item-subtitle v-text="pac.descricaoRegiao +' - '+ pac.descricaoAlmoxarifado"/>
                  </v-list-item-content>
                  <v-list-item-action-text>
                    {{ pac.descricaoUnidadeMedida }}
                    <br>
                    {{ pac.quantidade }}
                  </v-list-item-action-text>    
                </v-list-item>
              </v-list>
            </template>
          </PacDataTable>
        </div>         
      </CardPersonalizado>
    </v-col>
    <v-col cols="6"> <!-- Itens já/para cadastrados -->
      <CardPersonalizado 
                  title="Itens do Plano"
                  :showConteudo="true"
                  :loading="loading"
                  >
        <div>
          <v-list dense class="pa-0">
            <v-list-item v-for="item of pacsDaLicitacao" :key="item.id"
                @click="removeItem(item)" class="itens-pac del">
              <v-list-item-avatar>
                #{{ item.idPac }}
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title v-text="item.cdItem"/>
                <v-list-item-subtitle v-text="item.descricaoItem"/>
                <v-list-item-subtitle v-text="item.descricaoOrgao"/>
                <v-list-item-subtitle v-text="item.descricaoUnidade"/>
                <v-list-item-subtitle v-text="item.descricaoRegiao +' - '+ item.descricaoAlmoxarifado"/>
              </v-list-item-content>
              <v-list-item-action-text>
                {{ item.descricaoUnidadeMedida }}
                <br>
                {{ item.quantidade }}
              </v-list-item-action-text>              
            </v-list-item>
          </v-list>
        </div>         
      </CardPersonalizado>
    </v-col>
  </v-row>
</template>

<script>
import {ItemDeDominio} from "../../../api/itemsDominio";
import LookupDominio from "../common/LookupDominio";
import SearchBox from "../common/SearchBox";
import LicitacaoPacDataTable from "../datatables/LicitacaoPacDataTable";
import PacDataTable from "../datatables/PacDataTable";
import CardPersonalizado from "../common/CardPersonalizado";
//PKs
import {findAll as findAllItem} from "../../../api/item";
import {findAll as findAllOrgao} from "../../../api/orgao";
import {findAll as findAllUnidade} from "../../../api/unidade";
import {findAll as findAllPac} from '../../../api/licitacaoPac';
import {ApiError} from "../../../api/core";

export default {
  name: "LicitacaoForm",
  components: {SearchBox, LookupDominio, LicitacaoPacDataTable, CardPersonalizado, PacDataTable},
  props: {
    value: {
      type: Object
    }
  },
  data() {
    return {
      loading: false,
      error: null,
      pacsDaLicitacao: [],
      descItemPesquisa: "",
    }
  },
  computed: {
    orgao() {
      if (this.value.idOrgao == null) return null;
      return {
        id: this.value.idOrgao,
        descricaoFormatada: this.value.descricaoOrgao
      }
    },
    unidade() {
      if (this.value.idUnidade == null) return null;
      return {
        id: this.value.idUnidade,
        descricaoFormatada: this.value.descricaoUnidade
      }
    },
  },
  watch: {
    'value.idOrgao': {
      deep: true,
      handler(newValue, oldValue) {
        if (newValue !== oldValue) {
          this.$refs.comboUnidade.resetValues();
          this.$refs.comboUnidade.search();
        }
      }
    },
    'value.idUnidade': {
      deep: true,
      handler(newValue, oldValue) {
        if (newValue !== oldValue) {
          this.filtrarItensPacPorUnidade(newValue)
        }
      }
    },
  },
  methods: {  
    atualizaItensPacLicitacao(){
      this.value.idsPac = this.pacsDaLicitacao.map(d => d.idPac).join(",");
    },
    filtrarItensPacPorUnidade(idUnidade) {
      // Filtra os itens do array para manter apenas os que possuem o idUnidade igual ao passado
      this.pacsDaLicitacao = this.pacsDaLicitacao.filter(item => item.idUnidade === idUnidade);
    },
    loaderOrgao(page, search) {
      return findAllOrgao(page, {
        cdOrgao: search,
        descricao: search,
      })
    },
    loaderUnidade(page, search) {
      return findAllUnidade(page, {
        cdUnidade: search,
        descricao: search,
        idOrgao: this.value.idOrgao
      })
    },
    async buscaPacsLicitacao() {
      try {
        this.error = null;
        this.loading = true;
        const page = await findAllPac(1, {idLicitacao: (this.value.id ? this.value.id : -1)});
        this.pacsDaLicitacao = page.elements;
        this.atualizaItensPacLicitacao();
      } catch (err) {
        this.error = err;
        this.pacsDaLicitacao = [];        
        if (err instanceof ApiError) {
          this.$toast.error(err.message, {
            timeout: 6000
          })
        } else {
          this.$toast.error(`Não foi possível carregar a lista de Itens da licitação.`, {
            timeout: 6000
          })
        }   
      } finally {
        this.loading = false;
      }
    },
    addItemPac(item) {
      this.pacsDaLicitacao.push({
        id:null,
        idLicitacao:null,
        idPac: item.id,
        idItem: item.idItem,
        cdItem: item.cdItem,
        descricaoItem: item.descricaoItem,
        idOrgao: item.idOrgao,
        descricaoOrgao: item.descricaoOrgao,
        idUnidade: item.idUnidade,
        descricaoUnidade: item.descricaoUnidade,
        idRegiao: item.idRegiao,
        descricaoRegiao: item.descricaoRegiao,
        idAlmoxarifado: item.idAlmoxarifado,
        descricaoAlmoxarifado: item.descricaoAlmoxarifado,
        idUnidadeMedida: item.idUnidadeMedida,
        descricaoUnidadeMedida: item.descricaoUnidadeMedida,
        quantidade: item.quantidade,
        valorDetalhe: item.valorDetalhe,
        valorEstimado: item.valorEstimado,
        valorReferencial: item.valorReferencial,
        valorUltimaCompra: item.valorUltimaCompra,
        valorTotalPlanejado: item.valorTotalPlanejado,
        qtdConsumoAnterior: item.qtdConsumoAnterior,
        saldoEstoque: item.saldoEstoque,
      });
      this.atualizaItensPacLicitacao();
    },
    removeItem(item){
      const indexToRemove = this.pacsDaLicitacao.findIndex(pacsDaLicitacao => pacsDaLicitacao.idPac === item.idPac);
      if (indexToRemove !== -1) {
          this.pacsDaLicitacao.splice(indexToRemove, 1);
      }
      this.atualizaItensPacLicitacao();
    },
  },
  mounted(){
    this.buscaPacsLicitacao();
  }
}
</script>

<style scoped lang="scss">
::v-deep {
  .itens-pac {
    position: relative;
    border-bottom: 1px solid #dedede;

    &:after {
      background-color: transparent;
      transition: background-color 0.5s ease 0s;
    }

    &:hover:after {
      cursor: pointer;
      background-color: rgba(0, 0, 0, 0.3);
      position: absolute;
      right: 0;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      font-size: 1.5rem;
      padding: 0 100px;
      left: 0;
      bottom: 0;
      top: 0;
    }

    &.add:after {
      content: 'Adicionar';
      color: var(--v-primary-base);
    }

    &.del:after {
      content: 'Remover';
      color: var(--v-error-base);
    }
  }
}
</style>