<template>
  <base-view
      @click:add="criar"
      :permiteCadastrar="permiteCadastrar"
      @update:search-values="updateSearch"
      :search-columns="searchColumns">

    <CreateEditLicitacaoDialog :item="item" v-model="editDialogOpened"
                                  @onSave="() => $refs.dataTable.refresh()"/>
    <CreateEditGerarDocDialog :item="item" v-model="editDialogGerarDocOpened"/>

    <div>
      <LicitacaoDataTable ref="dataTable"
                             @click:edit="editar"
                             @click:remove="remover"
                             @click:gerarDoc="gerarDocumentos"
                             :search-values="searchValues"/>
    </div>
  </base-view>

</template>

<script>
import LicitacaoDataTable from "../../components/shared/datatables/LicitacaoDataTable";
import BaseView from "../BaseView";
import {remove} from "../../api/licitacao";
import CreateEditLicitacaoDialog from "../../components/shared/dialogs/CreateEditLicitacaoDialog";
import CreateEditGerarDocDialog from "../../components/shared/dialogs/CreateEditGerarDocDialog";
import {ApiError} from "../../api/core";
import {ItemDeDominio} from "../../api/itemsDominio";
import {mapActions,mapGetters} from "vuex";
//PKs
import {findAll as findAllItem} from "@/api/item";
import {findAll as findAllUnidade} from "@/api/unidade";
import {findAll as findAllOrgao} from "@/api/orgao";
import {findAll as findAllRegiao} from "@/api/regiao";
import {findAll as findAllAlmoxarifado} from "@/api/almoxarifado";
import {findAll as findAllUnidadeMedida} from "@/api/unidadeMedida";

export default {
  name: "index",
  components: {CreateEditLicitacaoDialog, CreateEditGerarDocDialog, BaseView, LicitacaoDataTable},
  data() {
    return {
      item: null,
      editDialogOpened: false,
      editDialogGerarDocOpened: false,
      permiteCadastrar: false,
      selectedItems: [],
      searchValues: {},
      searchColumns: [
        {
          name: 'Descrição',
          column: 'descricao',
          type: 'text'
        },
        {
          name: 'Item',
          column: 'idItem',
          type: 'autocomplete',
          optionsFactory: (search) => findAllItem(1,{
            cdItem: search,
            descricao: search
          })
              .then(r => {
                return r.elements.map(e => ({
                  key: e.id,
                  valor: e.descricaoFormatada
                }))
              })
        },
        {
          name: 'Unid Responsável',
          column: 'idUnidade',
          type: 'autocomplete',
          optionsFactory: (search) => findAllUnidade(1,{
            cdUnidade: search,
            descricao: search
          })
              .then(r => {
                return r.elements.map(e => ({
                  key: e.id,
                  valor: e.descricaoFormatada
                }))
              })
        },
        {
          name: 'Região',
          column: 'idRegiao',
          type: 'autocomplete',
          optionsFactory: (search) => findAllRegiao(1,{
            cdRegiao: search,
            descricao: search
          })
              .then(r => {
                return r.elements.map(e => ({
                  key: e.id,
                  valor: e.descricaoFormatada
                }))
              })
        },
        {
          name: 'Almoxarifado',
          column: 'idAlmoxarifado',
          type: 'autocomplete',
          optionsFactory: (search) => findAllAlmoxarifado(1,{
            cdAlmoxarifado: search,
            descricao: search
          })
              .then(r => {
                return r.elements.map(e => ({
                  key: e.id,
                  valor: e.descricaoFormatada
                }))
              })
        },
        {
          name: 'UnidadeMedida',
          column: 'idUnidadeMedida',
          type: 'autocomplete',
          optionsFactory: (search) => findAllUnidadeMedida(1,{
            cdUnidadeMedida: search,
            descricao: search
          })
              .then(r => {
                return r.elements.map(e => ({
                  key: e.id,
                  valor: e.descricaoFormatada
                }))
              })
        },
      ]
    }
  },
  computed: {
    ...mapGetters('auth',['user','isAuthenticated','nivelUsuarioLogado']),
  },
  methods: {
    ...mapActions('itemsDominio', ['fetch']),    
    updateSearch(search) {
      this.searchValues = search;
    },
    criar() {
      this.item = {
        id: null,
        idOrgao: null,
        descricaoOrgao: "",
        idUnidade: null,
        descricaoUnidade: "",							
        idUsuarioCadastro: null,
        nomeUsuarioCadastro: "",
        descricao: "",
        idsPac: "",
      };
      this.editDialogOpened = true;
    },
    editar(objeto) {
      this.item = JSON.parse(JSON.stringify(objeto));
      this.editDialogOpened = true;
    },
    gerarDocumentos(objeto) {
      this.item = JSON.parse(JSON.stringify(objeto));
      this.editDialogGerarDocOpened = true;
    },
    async remover({id}) {
      try {
        await remove(id);
        this.$toast.success(`Contratação removida com sucesso.`, {
          timeout: 3000
        });
        this.$refs.dataTable.refresh();
      } catch (err) {
        if (err instanceof ApiError) {
          this.$toast.error(err.message, {
            timeout: 6000
          })
        } else {           
          this.$toast.error(`Não foi possível remover a Contratação.`, {
            timeout: 6000
          })
        }
      }
    },
    async getPermissoes(){
      this.permiteCadastrar = await this.getPermissaoPorItemPerfil("Licitacao.cadastro",this.nivelUsuarioLogado);
    },
  },
  mounted() {
    this.getPermissoes();
  }
}
</script>

<style scoped lang="scss">

::v-deep {
  .v-data-table__wrapper {
    height: calc(100vh - 157px);
  }
}

</style>